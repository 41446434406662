<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card no-body>
          <b-card-body class="d-flex align-items-center">
            <b-avatar
              :variant="`light-warning`"
              size="45"
            >
              <feather-icon
                size="21"
                icon="HomeIcon"
              />
            </b-avatar>
            <div class="truncate ml-2 w-50">
              <div><span class="text-warning font-weight-bold font-small-2">{{ dataItem.cnumber }}</span></div>
              <h2 class="mb-25 font-weight-bolder">
                {{ dataItem.customers }}
              </h2>
              <span class="font-small-2">{{ dataItem.content }}</span>
            </div>
            <div class="ml-2 text-right w-50">
              <b-button
                v-b-tooltip.hover.v-info
                title="Ödeme Yap"
                variant="flat-info"
                class="btn-icon rounded-circle"
                :to="userData.id_customers ? '/payment':'/customer-payment/' +dataItem.id_customers"
                size="lg"
              >
                <feather-icon
                  icon="CreditCardIcon"
                  size="21"
                />
              </b-button>
              <b-button
                v-b-tooltip.hover.v-success
                variant="flat-success"
                title="Ödeme Takvimi"
                class="btn-icon rounded-circle"
                size="lg"
                :to="'/payment-collections'"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="21"
                />
              </b-button>
              <b-button
                v-b-tooltip.hover.v-danger
                variant="flat-danger"
                title="Ödeme İtirazları"
                class="btn-icon rounded-circle"
                size="lg"
                :to="'/disapproval-payments'"
              >
                <feather-icon
                  icon="XCircleIcon"
                  size="21"
                />
              </b-button>
              <b-button
                v-b-tooltip.hover.v-secondary
                variant="flat-secondary"
                title="Ödeme Geçmişi"
                class="btn-icon rounded-circle"
                size="lg"
                :to="'/payment-history'"
              >
                <feather-icon
                  icon="ArchiveIcon"
                  size="21"
                />
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <statistic-card
          :statistic="counters.debit | toCurrency"
          icon="ClockIcon"
          statistic-title="Borç"
          color="warning"
          prefix="₺"
        />
      </b-col>
      <b-col>
        <statistic-card
          :statistic="counters.payment | toCurrency"
          icon="CheckCircleIcon"
          statistic-title="Ödenen"
          color="success"
          prefix="₺"
        />
      </b-col>
      <b-col>
        <statistic-card
          :statistic="counters.amount | toCurrency"
          icon="BriefcaseIcon"
          statistic-title="Güncel Bakiye"
          color="info"
          prefix="₺"
        />
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>İşlemler</b-card-title>
        <b-button
          v-if="!userData.id_customers"
          variant="primary"
          :to="'/current-accounts/addline/' + $route.params.id"
        >
          <feather-icon icon="PlusIcon" />
          Oluştur
        </b-button>
      </b-card-header>
      <b-table
        v-if="linesData.length > 0"
        responsive="sm"
        :fields="fields"
        :items="linesData"
        striped
      >
        <template #cell(created)="data">
          {{ moment(data.item.created).format('DD.MM.YYYY') }}
          <div v-if="data.item.payment_collections">
            <small class="text-warning">
              <b>Ödeme Tarihi: </b><span class="text-body">{{ moment(data.item.pdate).format('DD.MM.YYYY') }}</span>
            </small>
            <div>
              <small class="text-success">Ödeme Takvimi Aktif.</small>
            </div>
          </div>
        </template>
        <template #cell(content)="data">
          {{ data.item.content }}
        </template>
        <template #cell(amount)="data">
          <div v-if="data.item.amount > '0'">
            <span class="text-success font-weight-bold">{{ data.item.amount | toCurrency }} TL</span>
          </div>
          <div v-else-if="data.item.amount < '0'">
            <span class="text-danger font-weight-bold">{{ data.item.amount | toCurrency }} TL</span>
          </div>
          <div v-else>
            <span class=" font-weight-bold">{{ data.item.amount | toCurrency }} TL</span>
          </div>
          <div>
            <small class="text-muted">{{ data.item.payment_collection_status }}</small>
          </div>
          <div>
            <small
              v-if="data.item.customer_user"
              class="text-primary"
            ><strong>Firma Kullanıcısı: </strong>{{ data.item.customer_user }}</small>
            <small
              v-else-if="data.item.username"
              class="text-muted"
            >{{ data.item.username }}</small>
          </div>
          <div v-if="data.item.id_payments">
            <b-badge variant="success">
              <feather-icon
                icon="CreditCardIcon"
                class="mr-25"
              />
              <span>Online Ödeme</span>
            </b-badge>
          </div>
        </template>
        <template #cell(control)="data">
          <div
            v-if="!userData.id_customers"
            class="text-right"
          >
            <list-buttons
              :edit-action="'/current-accounts/editline/' + data.item.id"
              :delete-action="removeData"
              :data-id="data.item.id"
            />
          </div>
        </template>
      </b-table>
      <b-alert
        v-else
        variant="info"
        show
        class="ml-2 mr-2"
      >
        <div class="alert-body text-center">
          <div>
            <FeatherIcon
              icon="InfoIcon"
              size="16"
            />
          </div>
          <p class="lead">
            İşlem Kaydı bulunmuyor.
          </p>
        </div>
      </b-alert>
      <b-card-footer>
        <b-pagination
          v-if="linesData.length > 0"
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BAlert,
  BCard, BCardFooter, BCardHeader, BCardTitle, BPagination, BTable, BButton, BRow, BCol, BCardBody, BAvatar, VBTooltip, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ListButtons from '@/layouts/components/common/ListButtons.vue'
import StatisticCard from '@/views/CurrentAccounts/Components/StatisticCard.vue'

const tableName = 'current_account_lines'

export default {
  name: 'View',
  components: {
    BAvatar,
    BCardBody,
    StatisticCard,
    BAlert,
    BCardFooter,
    BPagination,
    ListButtons,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BRow,
    BCol,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'created',
          label: 'İŞLEM TARİHİ',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'content',
          label: 'AÇIKLAMA',
        },
        {
          key: 'amount',
          label: 'TUTAR',
          thClass: 'width-100 text-right text-nowrap',
          tdClass: 'width-100 text-right text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.content AS content`,
          `${tableName}.amount AS amount`,
          `${tableName}.pdate AS pdate`,
          `${tableName}.id_payments AS id_payments`,
          'users.name AS username',
          'customer_users.name AS customer_user',
          'payment_collections.id AS payment_collections',
          'payment_collection_status.title AS payment_collection_status',
        ],
        where: {
          'current_account_lines.id_current_accounts': this.$route.params.id,
        },
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['currentAccounts/dataItem']
    },
    linesData() {
      return this.$store.getters['currentAccountLines/dataList']
    },
    counters() {
      return this.$store.getters['currentAccountLines/counters']
    },
    dataCounts() {
      return this.$store.getters['currentAccountLines/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('currentAccounts/getDataItem', this.$route.params.id)
        .then(response => {
          if (response) {
            this.$store.dispatch('currentAccountLines/getDataList', this.dataQuery)
          }
        })
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('currentAccountLines/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
